<template>
    <BlockUI :blocked="loadingCard">
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div>
                <Dropdown id="ptype_id" dataKey="ptype_id" v-model="filters.producttype_id" :loading="loadingDropdownProductType"
                    :options="dataDropdownProductType"
                    optionLabel="label" optionValue="ptype_id" placeholder="Pilih Tipe Produk"
                    :filter="true" @filter="searchDropdownProductType($event, 'filter')"
                    />   
            </div>
            <!-- <Badge value="Export Excel" size="large" severity="success" @click="exportExcelCSV('xlsx')" class="detail_active" ></Badge> -->
        </div>
        <Chart type="bar" :data="dataChart" :options="options"/>
    </BlockUI>
</template>

<script>

export default {
    props: [
		"filter2",
		// "startDate",
		// "endDate",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingCard: false,
            loadingExcel: false,
            loadingCsv: false,
            loadingDropdownProductType: false,

            // dataDropdown
            dataDropdownProductType: null,

            // filter
            filters: {
                producttype_id: 1,
            },

            percentage: null,
            dataChart: null,
                options: {
            indexAxis: 'y',
            elements: {
                bar: {
                    borderWidth: 2,
                }
            },
            responsive: true,
            plugins: {
                legend: {
                    position: 'bottom',
                    display: true,
                    labels: {
                        generateLabels: () => [
                            {
                                text: 'Internal Product',
                                fillStyle: '#007bff', 
                                strokeStyle: '#007bff',
                                hidden: false,
                                datasetIndex: 1
                            },
                            {
                                text: 'Competitor Product',
                                fillStyle: '#6c757d', 
                                strokeStyle: '#6c757d',
                                hidden: false,
                                datasetIndex: 0
                            }
                        ]
                    },
                    onClick: null,
                },
            }
        },
        }
    },
	mounted() {
        this.getData();
        this.searchDropdownProductType('');
        this.filters.producttype_id = 1;
	},
    computed:{
    },
    watch: {
        filters: {
            handler() {
                setTimeout(() => {
                    this.getData()
                }, 500);
            },
            deep: true
        },
        filter2: {
            handler() {
                setTimeout(() => {
                    this.getData();
                }, 500);
            },
            deep: true
        },
        // endDate: {
        //     handler() {
        //         setTimeout(() => {
        //             if(
        //                 this.endDate !== null && 
        //                 this.endDate !== 'Invalid date'
        //             ){  
        //                 this.getData();
        //             }
        //         }, 500);
        //     },
        // }
    },
	methods: {
        // DROPDOWN
        searchDropdownProductType(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dptype.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownProductType = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-product-type',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownProductType = res.data.data;
                            this.loadingDropdownProductType = false;
                        } else if (purpose == null) {
                            this.dataDropdownProductType = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        getData(){

            this.loadingCard= true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/competitor-activity-chart',
                params: {
                    "periode": this.filter2.period,
                    "ptype_id": [this.filters.producttype_id],
                    // "tanggal_mulai": this.startDate,
                    // "tanggal_akhir": this.endDate,
                }
            })
            .then(res => {
                this.dataChart = res.data.data;
                this.percentage =  res.data.data.datasets[0].persen;
                this.loadingCard= false;
                
            })
            .catch((err) => {
                this.loadingCard= false;
                console.log(err);
            });
        },
        // EXPORT
        exportExcelCSV(ext){
            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/competitor-activity-chart/export',
                responseType: 'blob',
                params: {
                    "ext" : ext,
                    "periode": this.filter2.period,
                    "ptype_id": this.filters.producttype_id,
                }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Competitor Activity Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
	},
}
</script>

<style>
#chartjs-tooltip{
    background: #ddd;
    padding: 8px
}
.external-tooltip {
  position: absolute;
  font-family: Arial, sans-serif;
  font-size: 12px;
  z-index: 9999;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.external-tooltip table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
}

.external-tooltip th,
.external-tooltip td {
  padding: 6px 8px;
  text-align: left;
}

.external-tooltip th {
  background-color: #333;
  color: #fff;
  font-weight: bold;
}

.external-tooltip td {
  background-color: #fff;
  border-top: 1px solid #ddd;
}

.external-tooltip td span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  vertical-align: middle;
}

.external-tooltip .tooltip-title {
  font-weight: bold;
  margin-bottom: 6px;
}

.external-tooltip .tooltip-body {
  margin-top: 6px;
}


.detail_active{
    transition: all 0.3s; /* untuk memberikan efek transisi saat hover */
}
.detail_active:hover {
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2); /* meningkatkan efek shadow */
    transform: scale(1.01); /* mengubah skala card */
    cursor: pointer; /* mengubah cursor menjadi bentuk pointer saat hover */
}
</style>